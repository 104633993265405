//#region Exclusives
export type ExclusiveCodes =
    'Backer_Stone' |
    'Wild_Boar' |
    'Ostrich' |
    'House' |
    'White_Bear_Outfit';

export const ExclusivesData: {
    [key in ExclusiveCodes]: {
        label: string,
        rewards: number[]
    }
} = {
    ['Backer_Stone']: {
        label: "Kickstarter Backer Stone",
        rewards: [9176, 9177, 9178, 9179, 9180, 9181, 9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9190, 9191]
    },
    ['Wild_Boar']: {
        label: "Exclusive Wild Boar",
        rewards: [9178, 9179, 9181, 9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9190, 9191]
    },
    ['Ostrich']: {
        label: "Exclusive Ostrich",
        rewards: [9179, 9181, 9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9190, 9191]
    },
    ['House']: {
        label: "Exclusive House",
        rewards: [9181, 9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9190, 9191]
    },
    ['White_Bear_Outfit']: {
        label: "Exclusive Wild Bear Outfit",
        rewards: [9181, 9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9190, 9191]
    }
}
//#endregion

//#region  Rewards
const rewardsWithBeta: number[] = [9179, 9181] // Be-ta shaman, My House Rocks
const rewardsWithAlpha: number[] = [9182, 9183, 9184, 9185, 9186, 9187, 9188, 9189, 9189, 9190, 9191, 10431, 10433, 10434, 10435] // EB: Al-fire -> Wise Elder

const rewardsWithCookbook = [ ...rewardsWithAlpha ]
const extraCookbook = [
    1579077,
    1579161,
    1579279,
    1579470,
    1579650,
    1579668,
    1580027,
    1580101,
    1580425,
    1580755,
    1580876,
    1580918,
    1580992,
    1581534,
    1581583,
    1581728,
    1581987,
    1582934,
    1582949,
    1583035,
    1584799,
    1584814,
    1585014,
    1588291,
    1593484,
    1593489,
    1593720,
    1596209,
    1596381,
    1597119,
    1604047,
    1604430,
    1604670,
    1611364,
    1611998,
    1612432,
    1613165,
    1613923,
    1614622,
    1614935,
    1615066,
    1615224,
    1615356,
    1615789,
    1615790,
    1615956,
    1616027,
    1616040,
    1616278,
    1616699,
    1616802,
    1617424,
    1617441,
    1617619,
    1712768
]
const hasCookbook = (reward: number, pid: number) => rewardsWithCookbook.includes(reward) || extraCookbook.includes(pid)

const rewardsWithColoringbook = [...rewardsWithBeta, ...rewardsWithAlpha]
const extraColoringbook = [
    1579630,
    1579669,
    1580004,
    1581480,
    1581585,
    1582890,
    1584814,
    1615356,
    1616946,
    1617619,
]
const hasColoringBook = (reward: number, pid: number) => rewardsWithColoringbook.includes(reward) || extraColoringbook.includes(pid)

const hasWallpapers = (_: number) => true

export const Rewards = {
    hasColoringBook,
    hasCookbook,
    hasWallpapers,
}
//#endregion